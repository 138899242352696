module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"title":"Kamal Insurance","type":"website","locale":"en_US","description":"Best Coverage, Incredible Prices, Quick and Easy.","images":[{"url":"https://uat.kamalinsurance.ae/main/images/ogimage.jpg","alt":"Kamal Insurance"}],"site_name":"Kamal Insurance"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
